import { React, useState, useLayoutEffect } from "react";
import idol from "../Images/Temple/godidol.JPG";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";
import { Autoplay, Pagination } from "swiper/modules";
import { Link } from "react-router-dom";
import {HashLink} from "react-router-hash-link";
import "../CSS/Home.css";
import mission from "../Images/target.png";
import vision from "../Images/vision (1).png";

import Footer from "../component/Footer";
// ----------------carvings------------------
import wed1 from "../Images/Temple/vasudeva.jpg";
import wed2 from "../Images/Temple/jail.jpg";
import wed3 from "../Images/Temple/makhan chor.jpg";
import wed4 from "../Images/Temple/kalinga.jpg";
import wed5 from "../Images/Temple/govardhan.jpg";
import wed6 from "../Images/Temple/krishnamarriage.jpg";
import wed7 from "../Images/Temple/tulabhra.jpg";
import wed8 from "../Images/Temple/Draupadi.jpg";
import wed13 from "../Images/Temple/sudhama.jpg";
import wed9 from "../Images/Temple/vishnu.jpg";
import wed10 from "../Images/Temple/geeta.jpg";
import wed11 from "../Images/Temple/vishwaroopa.jpg";
import wed12 from "../Images/Temple/IMG_4027.JPG";
// ------------------halls----------------
import kiran  from "../Images/Temple/kiran-bala1.JPG";
import terrace from "../Images/Temple/terrace.JPG";
import janardhan from "../Images/Temple/halldecor.JPG"
import banq from "../Images/Temple/temple.jpeg";
import banq1 from "../Images/Temple/saraswati.jpeg";
// -------------------icons---------------------
import hk from "../Images/elevator.png";
import cater from "../Images/buffet.png";
import electric from "../Images/parked-car.png";
import camera from "../Images/cctv (1).png";
import temple from "../Images/wedding-arch.png";
import fan from "../Images/air-conditioner.png";
// ----------------services---------------------
import templecarving from "../Images/Temple/templecarvings.JPG";
import templedecor from "../Images/Temple/templedecor1.JPG";
import templesikh from "../Images/Temple/templesikh.JPG";
import templesitting from "../Images/Temple/templesitting-decor.JPG";
import templepolice from "../Images/Temple/templepolice.JPG";
import templesofa from "../Images/Temple/templesittingsofa.JPG";
import dine from "../Images/Temple/dinning.JPG";
import program from "../Images/Temple/cultural-program.JPG";
import MetaTags from 'react-meta-tags';
const Home = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  const ReadMore = ({ children }) => {
    const text = children;
    const [isReadMore, setIsReadMore] = useState(true);
    const toggleReadMore = () => {
      setIsReadMore(!isReadMore);
    };
    return (
      <p className="text">
        {isReadMore ? text.slice(0, 170) : text}
        <span onClick={toggleReadMore} className="read-or-hide">
          {isReadMore ? "...Read more" : " Show less"}
        </span>
      </p>
    );
  };
  return (
    <div>
      {/* ========= SEO ========== */}

      <MetaTags>
        <title>Hall In Sion | Gokul Events</title>
        <meta
          name="description"
          content="Elevate your celebrations at our Hall in Sion.Gokul Events made unforgettable. Book now for an extraordinary experience!"
        />

        <meta property="og:title" content="Hall In Sion |Gokul Events" />
        <meta
          property="og:description"
          content="Elevate your celebrations at our Hall in Sion.Gokul Events made unforgettable. Book now for an extraordinary experience!"
        />
        <meta
          name="keywords"
          content="Hall In Sion,
          Party Hall In Sion,
          Banquet in Sion,
          Wedding Banquet In Sion,
          Best Banquet In Sion,
          Party Banquet In Sion,
          Gokul Hall,
          auditorium In Sion,
          AC Hall In Sion,
          AC Wedding Halls In Sion,
          Roof Top Hall In Sion"
        />
        <link rel="canonical" href="https://gokulevents.in/hall-in-sion" />
        <meta name="google-site-verification" content="VBB-V05nLFwZS9pwXxrINkZ79RHxEazT6XA_NDNapFA" />
      </MetaTags>

      {/* ======== SEO end ======= */}
      {/* <marquee style={{color:"red"}}>FREE HEALTH CHECK UP at GOKUL TEMPLE on 26th Jan 2024 between 10 to 2 pm. Preregister your name with your contact number at 8591732651</marquee> */}

      <section className="home ">
        <div className="HomeHead">
         
          <h1 className="d-none">Hall In Sion | Gokul Events</h1>
          <h2 className="">The perfect venue for your special occasion </h2>
          <span>Gokul Events </span>
          {/* <h2 data-aos="fade-left">The Best Banquet Hall for your party</h2> */}
          <Link to="/top-hall-sion">
            {" "}
            <button type="button" className="viewBtn1">
              View Gallery
            </button>
          </Link>
        </div>
      </section>

      <section className="intro-section spad">
        <div className="container">
          <div className="row intro-text">
            {/* <div className="col-lg-6"> */}
            <div className="col-lg-5">
              <Swiper
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination]}
                pagination={{
                  clickable: true,
                }}
                className="mySwiper1"
              >
                 <SwiperSlide>
                  <div className="intro-right">
                    <div className="krishnaImg1">
                      <img src={idol}alt="banquet halls" />
                    </div>
                    <h4>
                       Krishna's Idol with cow and flute
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="intro-right">
                    <div className="krishnaImg1">
                      <img src={wed2}alt="banquet halls" />
                    </div>
                  <HashLink to="/Murals#birth">  <h4>
                      Krishna's birth in jail with Devaki and Vasudeva(Janma)
                    </h4></HashLink>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="intro-right">
                    <div className="krishnaImg1">
                      <img src={wed1}alt="banquet halls" />
                    </div>
                  <HashLink to="/Murals#vasudev"><h4>
                      Crossing of the Yamuna river by Vasudeva with Krishna
                    </h4>
                    </HashLink>  
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="intro-right">
                    <div className="krishnaImg1">
                      <img src={wed3}alt="banquet halls" />
                    </div>
                    <h4>Stealing of butter from the Handi (Krishna Leela)</h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="intro-right">
                    <div className="krishnaImg1">
                      <img src={wed4} alt="banquet halls" />
                    </div>
                    <h4>The defeat of the serpent Kaliya (Kalinga Mardana)</h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="intro-right">
                    <div className="krishnaImg1">
                      <img src={wed5} alt="banquet halls" />
                    </div>
                    <h4>
                      Lifting of the Govardhana mountains (Govardhana Giridhari)
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="intro-right">
                    <div className="krishnaImg1">
                      <img src={wed6} alt="banquet halls" />
                    </div>
                    <h4>
                      Krishna’s marriage with Jambavati (Jambavati Kalyanam)
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="intro-right">
                    <div className="krishnaImg1">
                      <img src={wed7} alt="banquet halls" />
                    </div>
                    <h4> Rukmini Satyabhama Tulabhara</h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="intro-right">
                    <div className="krishnaImg1">
                      <img src={wed8} alt="banquet halls" />
                    </div>
                    <h4>
                      {" "}
                      Disrobing of Draupadi by the Kauravas (Draupadi
                      Vastrapaharana)
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="intro-right">
                    <div className="krishnaImg1">
                      <img src={wed10} alt="banquet halls" />
                    </div>
                    <h4>
                      {" "}
                      Krishna's teachings to Arjuna on the battlefield
                      (Geetopadesha)
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="intro-right">
                    <div className="krishnaImg1">
                      <img src={wed13}alt="banquet halls" />
                    </div>
                    <h4>
                      The deep bond of friendship between Krishna and Sudama
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="intro-right">
                    <div className="krishnaImg1">
                      <img src={wed9}alt="banquet halls" />
                    </div>
                    <h4> Vishnu’s promise to Vedavati (Srinivasa Kalyana)</h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="intro-right">
                    <div className="krishnaImg1">
                      <img src={wed12}alt="banquet halls" />
                    </div>
                    <h4> A devotee’s cry for liberation (Gajendra Moksha)</h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="intro-right">
                    <div className="krishnaImg1">
                      <img src={wed11}alt="banquet halls" />
                    </div>
                    <h4>The Divine Cosmic Form of Krishna (Vishwaroopa)</h4>
                  </div>
                </SwiperSlide>
              </Swiper>
              

            </div>

            <div className="col-lg-7">
              <div className="intro-left">
                {/* <div className="krishnaImg">
                  <img src={flute} alt="banquet halls" />
                </div> */}
                <div className="section-title">
                  {/* <span>A perfect event venue</span> */}
                  <h2
                    // className="react-reveal"
                    // style={{
                    //   animationFillMode: "both",
                    //   animationDuration: "1000ms",
                    //   animationDelay: "0ms",
                    //   animationIterationCount: 1,
                    //   opacity: 1,
                    //   animationName: "react-reveal-697937153832245-3",
                    // }}
                  >
                    Gopalakrishna Temple
                  </h2>
                  <p>
                    Gopalakrishna Temple in Sion was established over six
                    decades ago and has become the most popular Krishna temple
                    in the Sion Matunga vicinity. The adorable idol of Lord
                    Gopalakrishna with the cow standing in a riveting posture,
                    playing the flute with a smiling face is what draws hundreds
                    of devotees to the temple daily. The temple structure is
                    made in the South Kanara style architecture originating from
                    the Southern coastal belt of Karnataka and the <HashLink to="/Carvings#carv"> carvings</HashLink> on
                    the temple façade are in Hoysala Style.{" "}<br/><br/>
                  {/* </p>
                  <p> */}
                    Devotees visiting the premises are attracted by a beautiful
                    <HashLink to="/Carvings#Gopura">  Gopuram </HashLink>in white and gold against the building. The door
                    frame has <HashLink to="/Carvings#shayan">Anantashayana </HashLink>  on top and is flanked by huge
                    murthis of  <HashLink to="/Carvings#jaya">Jaya and Vijaya</HashLink> guarding the temple. The majestic
                    <HashLink to="/Carvings#Maha">  &nbsp;Mahadwara</HashLink>  or temple door has 14 events of ‘Bala Krishna’ or
                    little Krishna carved in wood, such as stories of Putana,
                    Shatakasura, Yashoda and Krishna on each door.{" "}<br/><br/>
                  {/* </p>
                  <p> */}
                    
                    {/* <ReadMore> */}
                      
                      As a devotee moves towards the  <HashLink to="/Carvings#lobby">main temple</HashLink>, the woodwork
                      on the ceiling greets the devotee with the carving of
                      Udupi Shri Krishna in the centre flanked by Vedavyasa and
                      Ganesha writing the Mahabharat on the top left and Adi
                      Kavi Valmiki writing the Ramayana on the top right. The
                      hall premises in front of the temple leaves one astounded
                      with the sheer magnificence and size of the ceiling panels
                      with their intricate woodwork. There are twelve panels
                      depicting a different episode in Krishna’s life that
                      surround the Vishwaroopa which is right in the centre of
                      the hall. On the sides of the hall are the ten 
                                          <Link to="/Dashavtar"><span> Dashavataras</span></Link>  moulded in metal which add to the aesthetic
                      beauty of the temple. The traditional Theertha Mantapa
                      decorated with wooden carvings depicting a few more
                      stories of Bala Krishna, is another site of attraction in
                      the main hall placed in front of the Garbhagudi. The
                      Mandir itself is built completely in stone and has 43
                      beautifully and intricately carved murthis of Keshava and
                      other deities in black stone all around. The Shikhara is
                      covered in Copper sheets.<br/> <br/>Inside the main Garbhagudi sits
                      our beloved Gopalakrishna, ever radiant and smiling and
                      blessing all his bhaktas who come to Him. Aarti is
                      performed thrice a day, 7.30am, 11.45am and 7.30pm on all
                      days. The temple is open from 7 am to 1 pm and from 5 pm
                      to 9 pm .
                      <br/>  <br/> Public events like Janmashtami, Prathistha Day,
                      Ganesh Chaturthi and Tulsi Pooja are the primary events of
                      Gokul and other events such as Satyanarayana Pooja every
                      Poornima and Shani Pooja every Amavasya are also conducted
                      monthly.<br/> <br/>Other important days like Shankara Jayanthi,
                      Madhwa Jayanti, Narasimha Jayanti are also celebrated.
                      Other secondary events that take place are private Homas /
                      Pujas, Marriages, Reception, Corporate Events, Birthday
                      and naming events. A visit to this beautiful temple is a
                      must for all bhaktas.<br/> <br/>A prayer with sincerity and devotion
                      will definitely help any bhakta to overcome challenges
                      that may come his way.
                    {/* </ReadMore>{" "} */}
                  </p>{" "}
                </div>
              </div>
            </div>

            {/* </div> */}

            {/* <div className="col-lg-6">
           
            
            </div> */}
          </div>
        </div>
      </section>

      {/* -------------------------------why choose us section------------------- */}
      <section className="why-Choose">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-12 order-lg-1 order-2">
            <div className="plan-start">
              <div className="section-title">
              <h2>
                  Mission &
                  Vision
                </h2>
              </div>
              
              </div>
              <div className="plan-start-block">
                <div className="row">
                  <div className="col-sm-1 col-xs-3 pad-0">
                    <div className="widget-icon">
                      <img src={mission} className="fas fa-envelope" />
                    </div>
                  </div>
                  <div className="col-sm-10 col-xs-9">
                    <div className="section-choose">
                      <h4 className="">Our Mission</h4>
                      <p className="">
                        <ul className="m-0 p-0">
                          <li>
                            <span>
                              {" "}
                              <i className="fa fa-check"></i> To serve and
                              support the Society and our Members by organizing
                              various religious, cultural and social functions
                              that blend traditional values with modern
                              thinking.{" "}
                            </span>
                          </li>
                          <li>
                            <i className="fa fa-check"></i>
                            <span>
                              {" "}
                              To help and support the community by promoting
                              education, nurturing talent and skills and helping
                              those in need.{" "}
                            </span>
                          </li>
                          <li>
                            <i className="fa fa-check"></i>
                            <span>
                              {" "}
                              To provide facilities for organizing community
                              events and programs.
                            </span>{" "}
                          </li>
                          <li>
                            <i className="fa fa-check"></i>
                            <span>
                              {" "}
                              To ensure that the Gokul Temple is a Beatific
                              place of worship for all devotees.
                            </span>
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="plan-start-block">
                  <div className="row">
                    <div className="col-sm-1 col-xs-3 pad-0">
                      <div className="widget-icon">
                        <img src={vision} className="fas fa-envelope" />
                      </div>
                    </div>
                    <div className="col-sm-10 col-xs-9">
                      <div className="section-choose">
                        <h4 className="section-heading">Our Vision</h4>
                        <p className="section-sub-heading">
                          To be recognized as a Model Association of Caring
                          Members, devoted to the service of society and
                          preservation of our cultural heritage.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="plan-start-block">
                  <div className="row">
                    <div className="col-sm-2 col-xs-3 pad-0">
                      <div className="widget-icon">
                        <i className="fa fa-play-circle" />
                      </div>
                    </div>
                   
                  </div>
                </div> */}
              </div>
            </div>
          
          </div>
        </div>
      </section>
      {/* --------------------------------banquets------------------------- */}
      <section className="kc-elm kc-css-160797 kc_row">
        <div className="container">
          <div className="row">
            <div className="aboutDesc">
              <h2 className="section-heading">Your Event Venue Guide</h2>
              {/* <p className="section-sub-heading">
                Selecting the perfect hall requires a keen understanding of the
                occasion at hand
              </p> */}
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4">
              <div class="card">
                <div class="img-box">
                  <img src={banq} />
                </div>
                <div class="content">
                  <div className="city-dtl">
                    <h2 className="city-dtl-heading">
                      Dr. Suresh Rao Gokul Hall
                    </h2>
                    <h5 className="listing_counter">Ground Floor</h5>
                    <p>
                      It’s the main Hall of Gokul Temple. This
                      place has one of the finest work in wood carving,
                      depicting the life of Lord Krishna...{" "}
                    </p>
               
                      {" "}
                      <HashLink to="/banquet-in-sion#gokulhall">  <button className="btn viewBtn">
                        {" "}
                          Read More
                  
                      </button></HashLink>
                    
                  </div>
                </div>
              </div>

             
            </div>
            <div className="col-lg-4 ">
              <div class="card">
                <div class="img-box">
                  <img src={banq1} />
                </div>
                <div class="content">
                  <div className="city-dtl">
                    <h2 className="city-dtl-heading">Saraswathi Auditorium</h2>
                    <h5 className="listing_counter">2nd floor</h5>
                    <p>
                      Saraswathi Auditorium, this air conditioned space has an
                      inbuilt state of the art sound and light system.
                    </p>
                    <HashLink to="/banquet-in-sion#saraswati-auditorium"> 
                      {" "}
                      <button className="btn viewBtn" to="Banquets">
                        {" "}
                         Read More
                      </button>
                      </HashLink>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6"> */}

              {/* </div> */}
            </div>

            <div className="col-lg-4">
              <div class="card">
                <div class="img-box">
                  <img src={janardhan} />
                </div>
                <div class="content">
                  <div className="city-dtl">
                    <h2 className="city-dtl-heading">M.K. Janardhan Hall</h2>
                    <h5 className="listing_counter">
                      4th floor
                      <span className="d-none">
                        This Hall in Sion is suitable for Wedding Ceremonies
                        ,Social Gatherings and Corporate Workshops etc.
                      </span>
                    </h5>
                    <p>
                      M.K. Janardhan Hall,  is the
                      perfect venue for wedding functions and other social
                      gathering.
                    </p>
                    <HashLink to="/banquet-in-sion#janardhan-ac-hall">  
                      {" "}
                      <button className="btn viewBtn" to="Banquets">
                        {" "}
                       Read More
                      </button>
                      </HashLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-2"> 

             </div>
            <div className="col-lg-4 mt-lg-5">
              <div class="card">
                <div class="img-box">
                  <img src={kiran} />
                </div>
                <div class="content">
                  <div className="city-dtl">
                    <h2 className="city-dtl-heading">
                      Kiran Bala Shagrithaya Hall
                    </h2>
                    <h5 className="listing_counter">5th Floor</h5>
                    <p>
                      <span className="d-none">
                        {" "}
                        This Hall in Sion is suitable for Temple Events . This
                        can take upto 300 floating capacity.
                      </span>
                      Kiran Bala Shagrithaya Hall is an AC Wedding hall which can fit in 225-250 guests.
                    </p>
                    <HashLink to="/banquet-in-sion#kiran-bala-shagrithaya-hall"> 
                      <button className="btn viewBtn" to="Banquets">
                        {" "}
                        Read More
                      </button>
                      </HashLink>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6"> */}

              {/* </div> */}
              {/* <div className="col-lg-6 mt-lg-2"> */}

              {/* </div> */}
            </div>
              
            <div className="col-lg-4 mt-lg-5">
              <div class="card">
                <div class="img-box">
                  <img src={terrace} />
                </div>
                <div class="content">
                  <div className="city-dtl">
                    <h2 className="">Terrace/Roof Top</h2>
                    <h5 className="listing_counter"> 8th Floor</h5>
                    <p>
                    Open space with a panoramic view of Mumbai.
                      <span className="d-none">
                        {" "}
                        It has a Panoramic View of Mumbai City. It is very
                        suitable for evening Corporate Events and other Social
                        Events.
                      </span>
                    </p>
                    <HashLink to="/banquet-in-sion#panoramic-view">
                      {" "}
                      <button className="btn viewBtn" to="Banquets">
                        {" "}
                         Read More
                      </button>
                    </HashLink>
                  </div>
                </div>
              </div>
              {/* <div className="col-lg-6"> */}

              {/* </div> */}
              {/* <div className="col-lg-6 mt-lg-2"> */}

              {/* </div> */}
            </div>
            <div className="col-lg-2"> 

</div>
          </div>
        </div>
      </section>

      {/* ---------------------------------contact----------------- */}
      <section class="offerSection" id="about_us">
        <div class="container ">
          <div class="offerTitle aos-init aos-animate" data-aos="fade-down">
            {/* <span>our offer</span> */}
            <h2>Creating Unforgettable Moments </h2>
            <p>Crafting memorable experiences at Gokul Events</p>
            <Link to="/party-banquet-in-sion">
              {" "}
              <button type="button" class="viewBtn1">
                Enquire Now{" "}
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* -----------------------------------qualities------------------ */}
      <section className="service p-lg-5 ">
        <div className="container">
          <div className="service-inner">
            <div className="row">
              <div className="col-lg-6 col-md-8 col-12">
                <div className="plan-discription" data-aos="fade-right">
                  <h3>
                    Having years of expertise
                    <br /> in our service{" "}
                  </h3>
                  <p>
                    With a legacy built upon years of experience, we stand as
                    your trusted partner in crafting exceptional moments. Our
                    journey through countless events has honed our expertise,
                    refining our skills to meet the evolving needs of our
                    clients. This reservoir of knowledge is now at your
                    disposal, as we bring together the art of event planning and
                    the science of execution to create seamless and
                    unforgettable experiences.
                  </p>
                  <p>
                    From intimate gatherings to grand galas, our team's depth of
                    experience ensures that every detail is meticulously
                    attended to.
                  </p>
                </div>
              </div>
              <div className="col-lg-6 col-md-4 col-12">
                <Swiper
                  autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                  }}
                  modules={[Autoplay, Pagination]}
                  pagination={{
                    clickable: true,
                  }}
                  className="mySwiper1"
                >
                  <SwiperSlide>
                    <div className="side-logo">
                      <img src={templecarving} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="side-logo">
                      <img src={templedecor} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="side-logo">
                      <img src={templesikh} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                    <SwiperSlide>
                    <div className="side-logo">
                      <img src={templesitting} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="side-logo">
                      <img src={templepolice} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="side-logo">
                      <img src={templesofa} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="side-logo">
                      <img src={dine} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                  <SwiperSlide>
                    <div className="side-logo">
                      <img src={program} alt="banquet halls" />
                    </div>
                  </SwiperSlide>
                </Swiper>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="homeSection section3 p-lg-5">
        <div className="container">
          <div className="homeSection ">
            <div className="row serviceList">
              <div className="col-lg-2 col-md-3 col-6 mt-2">
                <div className="servList">
                  <div className="serviceIcon">
                    <img src={fan} />
                  </div>
                  <h2>AC Halls</h2>
                  <h6>Centrally Airconditioned</h6>
                  <br />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-2">
                <div className="servList">
                  <div className="serviceIcon">
                    <img src={hk} />
                  </div>
                  <h2>Lifts</h2>
                  <h6>Four Passenger Lifts</h6>
                  <br />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-2">
                <div className="servList">
                  <div className="serviceIcon">
                    <img src={cater} />
                  </div>
                  <h2>Catering</h2>
                  <h6>Pure Vegetarian</h6>
                  <br />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-2">
                <div className="servList">
                  <div className="serviceIcon">
                    <img src={electric} />
                  </div>
                  <h2> Car Parking </h2>
                  <h6> Valet Parking (60 cars)</h6>
                  <br />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-2">
                <div className="servList">
                  <div className="serviceIcon">
                    <img src={camera} />
                  </div>
                  <h2>Security</h2>
                  <h6>CCTV camera</h6>
                  <br />
                </div>
              </div>
              <div className="col-lg-2 col-md-3 col-6 mt-2">
                <div className="servList">
                  <div className="serviceIcon">
                    <img src={temple} />
                  </div>
                  <h2>Decoration </h2>
                  <h6>Customized experience</h6>
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <hr></hr>

      <Footer />
    </div>
  );
};

export default Home;
