import React from 'react';
import "../CSS/Item.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Swiper, SwiperSlide } from "swiper/react";
// import { Navigation } from "swiper";
import { Autoplay, Pagination } from "swiper/modules";
// import Zoom from 'react-reveal/Zoom';

const Items = ({ data }) => {
    return (
        <>
            <div className='row'>
                {
                    data.map((value) => {
                        const {Category,CImage, CategoryName,type } = value

                        return (
                            <>
                                {/* <Zoom> */}
                                    <div className="col-lg-4 col-md-6 my-3" key={Category}>
                  {type!=='video'?          
                  <div className="single-event text-center">
                    <div className="event-img">
                      <a href="#">
                        <img src={CImage} alt="event" />
                        
                      </a>
                    </div>
                  
                  </div>
                  :
                  <div className="single-event text-center">
                    <div className="event-img">
                      
                      <video  controls  style={{height:"250px",width:"100%"}}>
                        <source  src={CImage} alt='video'/>
                        </video>
                        
                      
                    </div>
                  
                  </div>
                  }
                  
                  
                {/* <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      <a href="#">
                        <img src={templedecor} alt="event" />
                      </a>
                    </div>
                    <h4>
                      <a href="#">Conferences</a>
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      <a href="#">
                        <img src={templedecor} alt="event" />
                      </a>
                    </div>
                    <h4>
                      <a href="#">Seminars</a>
                    </h4>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="single-event text-center">
                    <div className="event-img">
                      <a href="#">
                        <img src={templedecor} alt="event" />
                      </a>
                    </div>
                    <h4>
                      <a href="#">Corporate meetings</a>
                    </h4>
                  </div>
                </SwiperSlide> */}
                    </div>
                                {/* </Zoom> */}
                            </>
                        )
                    })
                }

            </div>

            
        </>
    )
}

export default Items